<template>
    <div class="bank">
      <headTop :currentrStr="currentrStr" sfType='学生'></headTop>
      <div class="common-width bank-all">
        <a-modal
          title="加入班级"
          :visible="visible"
          @ok="onSubmitReg"
          okText="加入"
          cancelText="取消"
          @cancel="handleCancel"
        >
        <P>邀请码</P>
        <P> <a-input placeholder="请输入邀请码" v-model="joincode"/></P>
       
        </a-modal>
        <p class="bank-title"></p>
        <a-button type="primary" @click="showModal" style="float:right;margin-top:-35px;margin-bottom:10px;" >加入班级</a-button>
        <!-- <a-button type="primary" @click="showModalY" style="float:right;margin-top:-35px;margin-bottom:10px;margin-right:10px;" > 关联已有账号</a-button> -->
        <!-- <a-modal
          title="关联已有账号"
          :visible="visibleY"
          @ok="onSubmitRegY"
          okText="关联"
          cancelText="取消"
          @cancel="handleCancel"
        >
          <a-form-model
            class="reg"
            ref="ruleFormY"
            :model="formY"
            :rules="rulesY"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              ref="name"
              label="用户名"
              prop="name"
              :labelCol="{ span: 24, offset: 0 }"
              labelAlign="left"
            >
              <a-input placeholder="请输入您的用户名" v-model="formY.name">
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              label="密码"
              prop="pass"
              ref="pass"
              :labelCol="{ span: 4, offset: 0 }"
              labelAlign="left"
            >
              <a-input-password placeholder="请输入您的密码" v-model="formY.pass">
              </a-input-password>
            </a-form-model-item>
          
             <a-form-model-item>
              <a-button type="primary" @click="onSubmitReg" style="width: 100%">
                下一步
              </a-button>
            </a-form-model-item> 
          </a-form-model>
        </a-modal> -->
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="title" label="所属班级"> </el-table-column>
          <el-table-column prop="school" label="学校"> </el-table-column>
          <el-table-column prop="number" label="班级人数"> </el-table-column>
          <el-table-column prop="grade" label="年级"> </el-table-column>
          <el-table-column prop="course" label="课程"> </el-table-column>
          
       
        </el-table>
      </div>
    </div>
  </template>
    <script>
  import headTop from "../components/header.vue";
  import moment from "moment";
  export default {
    components: { headTop },
    data() {
      return {
        currentrStr: ["grade"],
        visible: false,
        // visibleY: false,
        joincode:'',
    
        tableData: [],
    
      };
    },
    mounted() {
        if( window.localStorage.getItem('anjing-token-group') !='student'){
      this.$router.push({
        path: "./index",
      });
    }
      this.initList();
    },
    methods: {
      initList() {
        let data = {
          op: "myclasses",
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/student/index.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              _this.tableData = response.data.data
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      showModal() {
        this.visible = true;
      },
    //   showModalY(){
    //       this.visibleY = true;  
    //   },
      checkSex(rule, value, callback) {
        // 如果证件类型不为空且是居民身份证且证件号不为空的情况下校验 性别和证件号是否一致
        if (
          this.form.type != undefined &&
          this.form.type == "居民身份证" &&
          this.card != ""
        ) {
          if (
            (this.form.card.substr(16, 1) % 2 == 1 && this.form.sex == "女") ||
            (this.form.card.substr(16, 1) % 2 == 0 && this.form.sex == "男")
          ) {
            callback(new Error("身份证号与性别不一致"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      },
      userName(rule, value, callback) {
        let data = {
          op: "checkeusername",
          username: this.form.name,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              callback();
            } else {
              callback(new Error(response.data.msg));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      checkGrade(rule, value, callback) {
        let structRight = RegExp(
          /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
        ).test(value);
        if (!structRight) {
          callback(new Error("请填写正确的手机号"));
   
        } else {
          callback();
        }
      },
      checkName(rule, value, callback) {
        // 居民身份证== 中文
        let structRight = RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/).test(
          value
        );
        // 非居民身份证==中文+英文
        let structLeft = RegExp(/^[A-Za-z]*(\s[A-Za-z]*)*$/).test(value);
        if (!(structRight || structLeft) && this.form.type == undefined) {
          callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
        } else {
          callback();
        }
      },
      checkEmail(rule, value, callback) {
        console.log(value);
        if (value != "") {
          let structRight = RegExp(
            /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
          ).test(value);
          if (!structRight) {
            callback(new Error("请填写正确的邮箱"));
            // this.codeBtn = true;
            // this.phoneCode = true;
          } else {
            let data = {
              op: "checkemail",
              email: this.form.email,
            };
            let _this = this;
            this.$ajax
              .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
              .then(function (response) {
                console.log(response);
                if (response.data.value == "ok") {
                  callback();
                } else {
                  callback(new Error(response.data.msg));
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else {
          callback();
        }
      },
      onSubmitReg() {
        // this.$refs.ruleForm.validate((valid) => {
        //   if (valid) {
            //create_child_acc
            // console.log("ok");
            let data = {
              op: "joinclass",
              joincode: this.joincode,
 
            };
            let _this = this;
            this.$ajax
              .post(this.url + "/student/index.do", _this.Qs.stringify(data))
              .then(function (response) {
                console.log(response);
                if (response.data.value == "ok") {
                 _this.initList(); 
                  _this.$message.info(response.data.msg);
                  _this.visible=false;
                
                } else {
                  _this.$message.info(response.data.msg);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        //   }
        // });
      },
      onSubmitRegY() {
        this.$refs.ruleFormY.validate((valid) => {
          if (valid) {
            //create_child_acc
            console.log("ok");
            let data = {
              op: "bind_child_acc",
              username: this.formY.name,
              password: this.formY.pass,
            };
            let _this = this;
            this.$ajax
              .post(this.url + "/parents/index.do", _this.Qs.stringify(data))
              .then(function (response) {
                console.log(response);
                if (response.data.value == "ok") {
                  _this.initList();
                  _this.$message.info(response.data.msg);
                  _this.visibleY=false;
                _this
                } else {
                  _this.$message.info(response.data.msg);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
      },
      handleCancel() {
        this.visible = false;
        this.visibleY = false;
      },
  
      // changeFun(){
      //     console.log(1)
      //     this.$confirm('确认关闭？')
      //       .then(_ => {
      //         done();
      //       })
      //       .catch(_ => {});
      //   }
      
    },
  };
  </script>
    <style scoped>
  .bank-all {
    margin-top: 30px !important;
    text-align: left;
  }
  /deep/ .ant-modal {
    top: 48px;
  }
  .bank-title{
      height: 20px;
      width: 100%;
      /* background-image: linear-gradient(to right , #0585ce, #ffffff); */
      /* border-left: 2px solid #001f48; */
      /* color: #fff; */
      line-height: 20px;
      font-size: 14px;
      padding-left: 20px;
      border-radius: 2px;
  }
  </style>
    